<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="editing"></th>
            <th class="text-center">Seed</th>
            <th class="text-center">Seed Og</th>
            <th class="text-center">Finish</th>
            <th class="text-left" >Team</th>
            <th class="text-left">Pool Result</th>
            <th class="text-left">Win/Loss</th>
            <th class="text-left">Set Win/Loss</th>
            <th class="text-left">Point Ratio</th>
            <th class="text-left">Point Differential</th>
            <th class="text-center">Advances</th>
          </tr>
        </thead>
        <tbody v-for="g in teamGroups" :key="g.id" :id="`teams-${g.id}`">
          <tr v-if="g.id === 'wait' && g.teams.length" class="bt">
            <td :colspan="editing ? '11' : '10'" class="text-center">
              <v-alert color="warning" text dense border="top">
                The below teams are awaiting results
              </v-alert>
            </td>
          </tr>
          <tr v-if="g.id === 'out' && g.teams.length" class="bt">
            <td :colspan="editing ? '11' : '10'" class="text-center">
              <v-alert color="error" text dense border="top">
                The teams below that did not advance to this round
              </v-alert>
            </td>
          </tr>
          <tr class="team" v-for="team in g.teams" :key="`teamRow-${g.id}-${team.teamId}-${team.roundFinish}`">
            <td v-if="editing" width="46" style="max-width: 46px">
              <v-icon small style="cursor: move" class="sortHandle" v-if="team.in && g.id === 'in'">fas fa-bars</v-icon>
            </td>
            <td class="text-center" width="46">{{team.seed > 0 && team.seed &lt; 198 ? team.seed : ''}}</td>
            <td class="text-center" width="46">{{team.oseed > 0 && team.oseed &lt; 198 ? team.oseed : ''}}</td>
            <td class="text-center" width="46">{{team.roundFinish > -100000 ? team.roundFinish : ''}}</td>
            <td>{{team.name}}</td>
            <td>{{team.map}}</td>
            <td>{{team.prevSum ? `${team.prevSum.matchWins}-${team.prevSum.matchLosses} (${(team.prevSum.matchWinRatio * 100).toFixed(2)}%)` : 'NA'}}</td>
            <td>{{team.prevSum ? `${team.prevSum.setWins}-${team.prevSum.setLosses} (${(team.prevSum.setWinRatio * 100).toFixed(2)}%)` : 'NA'}}</td>
            <td>{{team.prevSum ? `${(team.prevSum.pointsFor/team.prevSum.pointsAgainst).toFixed(3)}` : 'NA'}}</td>
            <td>{{team.prevSum ? `${team.prevSum.pointDiff}` : 'NA'}}</td>
            <td>
              <div class="justify-center layout align-center pa-0">
                <v-checkbox
                  v-model="team.in"
                  :disabled="!editing || g.id === 'wait'"
                  hide-details
                  color="color3"
                  readonly
                  class="shrink ma-0 pa-0"
                  @click.stop="onInClick(team.teamId)"
                ></v-checkbox>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog
      v-model="dialog"
      max-width="300px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="subheading text-center">
          <div>Please wait until all results are in to edit the teams or seeding.</div>
          <v-btn color="color3 color3Text--text" @click.stop="dialog=false">OK</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import firstBy from 'thenby'
import { Sortable } from '@shopify/draggable'
import TeamSummary from '@/classes/TeamSummary'
import max from 'lodash.max'
import uniqBy from 'lodash.uniqby'

export default {
  props: [
    'teams',
    'showMap',
    'table',
    'repField',
    'mapLabel',
    'hideHeaders',
    'editing',
    'bracket'
  ],
  data () {
    return {
      sorting: false,
      editableTeams: [],
      sortable: null,
      page: { sortBy: 'seed' },
      tKey: 0,
      loading: false,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getTeam', 'view', 'getPoolTeamSummary']),
    myTeams () {
      if (this.editing) return this.editableTeams
      return (
        this.teams &&
        this.teams.map(t => {
          const team = this.getTeam(t.teamId)
          const bTeam = this.bracket.teams.find(f => f.teamId === t.teamId)
          let s = (bTeam ? bTeam.seed : t.seed) || 99
          if (!t.in) s += 100
          t.seed = s
          t.oseed = s
          t.name = team.name
          t.repping = team.getRep(this.repField)
          t.prevSum = t.fromPoolId && new TeamSummary(this.getPoolTeamSummary(t.teamId, t.fromPoolId))
          return t
        }).sort(firstBy('seed'))
      )
    },
    teamsIn () {
      return this.myTeams.filter(f => f.in && f.seed > 0)
    },
    inIds () {
      return this.teamsIn.map(m => m.teamId)
    },
    bottomSeed () {
      return max(this.teamsIn.filter(f => f.seed || 0).map(m => m.seed))
    },
    teamsWaiting () {
      return this.myTeams.filter(f => (f.seed < 0 || f.roundFinish < 0) && !this.inIds.includes(f.teamId))
    },
    teamsOut () {
      return this.myTeams.filter(f => !f.in && f.seed >= 0 && !this.inIds.includes(f.teamId))
    },
    teamsErr () {
      return this.myTeams.filter(f => f.seed === 0)
    },
    teamGroups () {
      return [
        {
          id: 'in',
          teams: this.teamsIn
        },
        {
          id: 'wait',
          teams: uniqBy(this.teamsWaiting, 'teamId')
        },
        {
          id: 'out',
          teams: uniqBy(this.teamsOut, 'teamId')
        },
        {
          id: '???',
          teams: this.teamsErr
        }
      ]
    },
    hideHeaderRow () {
      return this.hideHeaders !== undefined && this.hideHeaders !== false
    },
    headers () {
      var h = [
        { text: 'Seed', align: 'center', sortable: false },
        { text: 'Team', align: 'left', sortable: false },
        { text: this.mapLabel, align: 'left', sortable: false },
        { text: 'Win/Loss', align: 'center', sortable: false },
        { text: 'Point Ratio', align: 'center', sortable: false },
        { text: 'Point Differential', align: 'center', sortable: false },
        { text: 'Advances', align: 'center', sortable: false }
      ]
      this.editing && h.splice(0, 0, { sortable: false })
      return h
    },
    breakRow () {
      return this.myTeams.findIndex(f => !f.in)
    },
    dirty () {
      return !!this.editableTeams.filter(f => f.dirty).length
    },
    dto () {
      return this.editableTeams.map(t => {
        return {
          teamId: t.teamId,
          map: t.map,
          roundFinish: t.ofinish,
          seed: t.seed,
          in: t.in,
          fromPoolId: t.fromPoolId
        }
      })
    }
  },
  methods: {
    sort () {
      console.log('here')
      this.editableTeams.sort(firstBy('in', -1)
        .thenBy(function (t1, t2) { return t1.prevSum.finish - t2.prevSum.finish })
        .thenBy(function (t1, t2) { return t2.prevSum.matchWinRatio - t1.prevSum.matchWinRatio })
        .thenBy(function (t1, t2) { return t2.prevSum.pointDiff - t1.prevSum.pointDiff }))
      this.reseed()
    },
    edit () {
      if (this.teams.filter(f => !f.in && f.seed < 0).length) {
        this.$emit('cancel')
        this.cancel()
        this.dialog = true
        return
      }
      this.editableTeams = this.teams.map(t => {
        const bTeam = this.bracket.teams.find(f => f.teamId === t.teamId)
        let s = (bTeam ? bTeam.seed : t.seed) || 99
        const mapSplit = t.map.split(' ')
        if (!t.in) (s) += 100
        return {
          teamId: t.teamId,
          seed: s || 999,
          oseed: s,
          name: t.name,
          map: t.map,
          placeinPool: mapSplit.length > 0 ? mapSplit[0] : 'ZZZ',
          repping: t.repping,
          in: t.in,
          oin: t.in,
          finish: t.roundFinish,
          ofinish: t.roundFinish,
          fromPoolId: t.fromPoolId,
          roundFinish: t.roundFinish,
          prevSum: t.prevSum,
          get dirty () {
            return this.seed !== this.oseed || this.in !== this.oin
          }
        }
      }).sort(firstBy('seed'))
      this.makeSortable()
    },
    cancel () {
      this.editableTeams = []
      this.sortable && this.sortable.destroy()
    },
    makeSortable () {
      const table = window.document.getElementById('teams-in')
      if (!table) {
        console.log('no table')
        return
      }
      if (this.sortable) {
        this.sortable.destroy()
      }
      const sortable = new Sortable(table, {
        draggable: '.team',
        handle: '.sortHandle',
        mirror: {
          constrainDimensions: true
        }
      })

      sortable.on('sortable:stop', this.dragReorder)
      this.sortable = sortable
    },
    onInClick (teamId) {
      const team = this.myTeams.find(f => f.teamId === teamId)
      team.seed = (this.bottomSeed + 1)
      team.in = !team.in
      this.editableTeams.sort(firstBy('in', -1).thenBy('oseed'))
      this.reseed()
    },
    dragReorder ({ oldIndex, newIndex }) {
      const movedItem = this.editableTeams.splice(oldIndex, 1)[0]
      this.editableTeams.splice(newIndex, 0, movedItem)
      this.reseed(true)
    },
    reseed (sort) {
      let i = 1
      this.editableTeams.forEach(t => {
        t.seed = i++
        if (sort && t.seed !== t.finish) t.finish = t.seed
      })
      this.tKey += 1
      this.$nextTick(() => {
        this.makeSortable()
      })
    }
  },
  watch: {
    editing (val) {
      val ? this.edit() : this.cancel()
    },
    dirty (val) {
      this.$emit('dirty-change', val)
    }
  }
}
</script>

<style scoped>
.shrink .v-input__slot {
  margin-bottom: 0 !important;
}
.draggable-mirror {
  background-color: #fff;
  border: 1px solid var(--v-color2-base);
}
.draggable--over {
  background-color: var(--v-color3-base) !important;
  color: var(--v-color3Text-base) !important;
}
.draggable--over .v-icon {
  display: none;
}
.bt >>> tr:not(:last-child) td:last-child {
  border-top: 2px solid var(--v-color3-base);
}
</style>

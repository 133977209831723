export default class TeamSummary {
  dualWins = 0
  dualLosses = 0
  matchWins = 0
  matchLosses = 0
  setLosses = 0
  setWins = 0
  pointsFor = 0
  pointsAgainst = 0

  constructor (dto) {
    Object.assign(this, dto)
  }

  get dualsPlayed () { return this.dualWins + this.dualLosses }
  get dualWinRatio () { return this.dualWins === 0 ? -this.dualLosses : this.dualWins / this.dualsPlayed }
  get matchesPlayed () { return this.matchWins + this.matchLosses }
  get matchWinRatio () { return this.matchWins === 0 ? 0 : this.matchWins / this.matchesPlayed }
  get setsPlayed () { return this.setWins + this.setLosses }
  get setWinRatio () { return this.setWins === 0 ? -this.setLosses : this.setWins / this.setsPlayed }
  get pointDiff () { return this.pointsFor - this.pointsAgainst }
  get pointDiffRatio () { return this.matchesPlayed ? this.pointsFor + this.pointsAgainst === 0 ? 0 : this.pointsFor / this.pointsAgainst : null }
}
